import React from 'react'
import { Link } from 'gatsby'
import Seo from "../components/seo"
import { removeFormValues } from "../services/form"
import { sessionKeys } from "../services/const"

class ContactComplete extends React.Component {
  componentDidMount = () => {
    removeFormValues(sessionKeys.contactForm);
  }

  render = () => {
    return (
      <>
        <Seo title="お問い合わせ " />
        <div id="pageTitle">
          <h1>
            お問い合わせ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>お問い合わせ</li>
          </ul>
        </div>

        <div id="main">

          <section className="contents_block">
            <p className="header01">お問い合わせの送信が完了しました。</p>
            <p>お問い合わせの送信が完了しました。貴重なご意見誠にありがとうございました。</p>
          </section>

        </div>
      </>
    )

  }
}

export default ContactComplete;